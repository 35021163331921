<template>
<!-- 全明健身发展指数 -->
    <div>
        <div class="goBack">
            <div @click="getBack('shaoxingone')">
                <img src="../../assets/images/return.png" alt="">
            </div>
            <span>全民健身发展指数</span>
        </div>
        <div class="posit-left">
            <fitnessindex></fitnessindex>
        </div>
    </div>
    
</template>

<script>
import {show} from '../../assets/js/animate'
export default {
    components: {
        fitnessindex: () => import('../charts/nationalfitness/fitnessindex.vue'),
    },
    methods: {
        getBack(showdata){
            let _this = this;
            show(showdata, _this);
			$('#amap').show();
        }
    }
}
</script>

<style scoped>

</style>